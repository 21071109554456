<template>
  <!-- 培训协议 -->
  <div class="jxEvaluationRecord">
    <van-nav-bar title="历史评价记录"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight" />
    <div class="container">
        <div class="searchBox">
            <form action="/">
                <van-search
                    background="#eceaea"
                    shape="round"
                    show-action
                    v-model="value"
                    placeholder="名字/层级/日期"
                    @search="onSearch"
                >
                    <template #action>
                         <div @click="openNyr">
                            <svg-icon icon-class="jxEvaluationRecordPBtn"></svg-icon>
                        </div>
                    </template>
                </van-search>
            </form>
        </div>
        <div class="listBox" style="margin-top:0;">
            <h2>
                <span>高层评价方案</span>
            </h2>
            <div class="listRow" @click="visitDetails">
                <van-row type="flex" class="listVanRow">
                    <van-col span="4">
                        <img class="user_pic_cls" src="@/assets/img/interview_pic1.png" alt="">
                    </van-col>
                    <van-col span="20" class="listVanRowRight">
                        <div class="cell_list">
                            <van-cell title="202105考核周期（考核等级A）" label="2021-05-01" center is-link></van-cell>
                        </div>
                        <div class="borderBottomAbsolute"></div>
                    </van-col>
                </van-row>
            </div>
            <div class="listRow">
                <van-row type="flex" class="listVanRow">
                    <van-col span="4">
                        <img class="user_pic_cls" src="@/assets/img/interview_pic1.png" alt="">
                    </van-col>
                    <van-col span="20" class="listVanRowRight">
                        <div class="cell_list">
                            <van-cell title="202105考核周期（考核等级A）" label="2021-05-01" center is-link></van-cell>
                        </div>
                        <div class="borderBottomAbsolute"></div>
                    </van-col>
                </van-row>
            </div>
            <div class="listRow">
                <van-row type="flex" class="listVanRow">
                    <van-col span="4">
                        <img class="user_pic_cls" src="@/assets/img/interview_pic1.png" alt="">
                    </van-col>
                    <van-col span="20" class="listVanRowRight">
                        <div class="cell_list">
                            <van-cell title="202105考核周期（考核等级A）" label="2021-05-01" center is-link></van-cell>
                        </div>
                        <div class="borderBottomAbsolute"></div>
                    </van-col>
                </van-row>
            </div>
            <div class="listRow">
                <van-row type="flex" class="listVanRow">
                    <van-col span="4">
                        <img class="user_pic_cls" src="@/assets/img/interview_pic1.png" alt="">
                    </van-col>
                    <van-col span="20" class="listVanRowRight">
                        <div class="cell_list">
                            <van-cell title="202105考核周期（考核等级A）" label="2021-05-01" center is-link></van-cell>
                        </div>
                        <div class="borderBottomAbsolute"></div>
                    </van-col>
                </van-row>
            </div>
        </div>
        <div class="listBox">
            <h2>
                <span>中层评价方案</span>
            </h2>
            <div class="listRow">
                <van-row type="flex" class="listVanRow">
                    <van-col span="4">
                        <img class="user_pic_cls" src="@/assets/img/interview_pic1.png" alt="">
                    </van-col>
                    <van-col span="20" class="listVanRowRight">
                        <div class="cell_list">
                            <van-cell title="202105考核周期（考核等级A）" label="2021-05-01" center is-link></van-cell>
                        </div>
                        <div class="borderBottomAbsolute"></div>
                    </van-col>
                </van-row>
            </div>
            <div class="listRow">
                <van-row type="flex" class="listVanRow">
                    <van-col span="4">
                        <img class="user_pic_cls" src="@/assets/img/interview_pic1.png" alt="">
                    </van-col>
                    <van-col span="20" class="listVanRowRight">
                        <div class="cell_list">
                            <van-cell title="202105考核周期（考核等级A）" label="2021-05-01" center is-link></van-cell>
                        </div>
                        <div class="borderBottomAbsolute"></div>
                    </van-col>
                </van-row>
            </div>
            <div class="listRow">
                <van-row type="flex" class="listVanRow">
                    <van-col span="4">
                        <img class="user_pic_cls" src="@/assets/img/interview_pic1.png" alt="">
                    </van-col>
                    <van-col span="20" class="listVanRowRight">
                        <div class="cell_list">
                            <van-cell title="202105考核周期（考核等级A）" label="2021-05-01" center is-link></van-cell>
                        </div>
                        <div class="borderBottomAbsolute"></div>
                    </van-col>
                </van-row>
            </div>
            <div class="listRow">
                <van-row type="flex" class="listVanRow">
                    <van-col span="4">
                        <img class="user_pic_cls" src="@/assets/img/interview_pic1.png" alt="">
                    </van-col>
                    <van-col span="20" class="listVanRowRight">
                        <div class="cell_list">
                            <van-cell title="202105考核周期（考核等级A）" label="2021-05-01" center is-link></van-cell>
                        </div>
                        <div class="borderBottomAbsolute"></div>
                    </van-col>
                </van-row>
            </div>
        </div>
        <div class="listBox">
            <h2>
                <span>基层评价方案</span>
            </h2>
            <div class="listRow">
                <van-row type="flex" class="listVanRow">
                    <van-col span="4">
                        <img class="user_pic_cls" src="@/assets/img/interview_pic1.png" alt="">
                    </van-col>
                    <van-col span="20" class="listVanRowRight">
                        <div class="cell_list">
                            <van-cell title="202105考核周期（考核等级A）" label="2021-05-01" center is-link></van-cell>
                        </div>
                        <div class="borderBottomAbsolute"></div>
                    </van-col>
                </van-row>
            </div>
            <div class="listRow">
                <van-row type="flex" class="listVanRow">
                    <van-col span="4">
                        <img class="user_pic_cls" src="@/assets/img/interview_pic1.png" alt="">
                    </van-col>
                    <van-col span="20" class="listVanRowRight">
                        <div class="cell_list">
                            <van-cell title="202105考核周期（考核等级A）" label="2021-05-01" center is-link></van-cell>
                        </div>
                        <div class="borderBottomAbsolute"></div>
                    </van-col>
                </van-row>
            </div>
            <div class="listRow">
                <van-row type="flex" class="listVanRow">
                    <van-col span="4">
                        <img class="user_pic_cls" src="@/assets/img/interview_pic1.png" alt="">
                    </van-col>
                    <van-col span="20" class="listVanRowRight">
                        <div class="cell_list">
                            <van-cell title="202105考核周期（考核等级A）" label="2021-05-01" center is-link></van-cell>
                        </div>
                        <div class="borderBottomAbsolute"></div>
                    </van-col>
                </van-row>
            </div>
            <div class="listRow">
                <van-row type="flex" class="listVanRow">
                    <van-col span="4">
                        <img class="user_pic_cls" src="@/assets/img/interview_pic1.png" alt="">
                    </van-col>
                    <van-col span="20" class="listVanRowRight">
                        <div class="cell_list">
                            <van-cell title="202105考核周期（考核等级A）" label="2021-05-01" center is-link></van-cell>
                        </div>
                        <div class="borderBottomAbsolute"></div>
                    </van-col>
                </van-row>
            </div>
        </div>
    </div>
    <van-popup v-model="showPicker" position="bottom">
        <van-datetime-picker
            type="year-month"
            :min-date="minDate"
            :max-date="maxDate"
            @cancel="showPicker = false"
        />
    </van-popup>
  </div>
</template>

<script>
export default {
  data () {
    return {
        value: '',
        minDate: new Date(2020, 0, 1),
        maxDate: new Date(2025, 10, 1),
        currentDate: new Date(),
        showPicker:false
    }
  },
  methods: {
    formatter(type, val) {
        if (type === 'year') {
            return `${val}年`;
        } else if (type === 'month') {
            return `${val}月`;
        }
        return val;
    },
    onClickLeft () {
        this.$router.push('/home')
    },
    visitDetails () {
        this.$router.push('/jxEvaluationRecordDetails');
    },
    onClickRight () { 

    },
    onSearch(val) {
    //   Toast(val);
    },
    onCancel() {
    //   Toast('取消');
    },
    openNyr () {
        this.showPicker = true;
    }
  }
}
</script>

<style lang="less" scoped>
.jxEvaluationRecord {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    background: #eceaea;
    .van-nav-bar {
        background: #2b8df0;
        /deep/ .van-nav-bar__left {
            .van-icon {
                color: #fff;
            }
        }
        /deep/.van-nav-bar__title {
            color: #fff;
            font-size: 36px;
            font-family: Source Han Sans CN;
        }
        /deep/ .van-nav-bar__right {
            .van-nav-bar__text {
                color: #fff;
                font-family: Source Han Sans CN;
            }
        }
    }
    .container{
        .searchBox{
            /deep/.van-search{
                padding: 0.35rem 0.32rem;
                position: relative !important;
            }
            /deep/.van-search__content{
                background: #fff !important;
            }
            /deep/.van-search--show-action{
                padding-right: 0.32rem !important;
            }
            /deep/.van-search__action{
                position: absolute;
                padding: 0 0.71333rem 0 0.21333rem;
                right: 0;
                border-left: 1px solid #ddd;
            }
            /deep/.van-search{
                .van-cell{
                    padding: 0.13333rem 1rem 0.13333rem 0 !important;
                }
            }
        }
        
        .listBox{
            width: 100%;
            background: #fff;
            padding-bottom: 44px;
            margin-top: 24px;
            h2{
                height: 80px;
                display: flex;
                align-items: center;
                margin: 0 auto;
                padding: 0 0.32rem;
                border-bottom: 1px solid #cccccc;
                span{
                    font-size: 32px;
                    font-weight: bold;
                    color: #000;
                }
            }
        }
        .listRow{
            margin: 0 0.32rem;
            .user_pic_cls{
                width: 100%;
                max-width: 100%;
                display: block;
            }
            .listVanRow{
                margin-top: 0.35rem;
            }
        }
        .listVanRowRight{
            display: flex;
            align-items: center;
            position: relative;
            .cell_list{
                width: 100%;
                /deep/.van-cell__label{
                    margin-top: 0;
                }
            }
            /deep/.van-cell{
                padding: 0;
                padding-left: 0.3rem;
            }
        }
        .borderBottomAbsolute{
            position: absolute;
            width: calc(100% - 0.3rem);
            height: 1px;
            background: #ccc;
            right: 0;
            bottom: 0;
        }
    }   
}
</style>